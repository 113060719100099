
.has-droupdown{
    
}
.submenu-right-login{
    width:300px !important;
    left: -250px !important;
}
.submenu-right-language{
    width:100px !important;
    left: -190px !important;
}
.item-carrito{
}

.selected-lng{
    color:#ac0404 !important;
}

.btn-menu{
    background: transparent !important;
    border: 0 !important;
    font-weight: bold;
    color: #c6c9d8;
    font-size: 16px;
}

#login-dp{
    min-width: 250px;
    padding: 14px 14px 0;
    overflow:hidden;
    background-color:rgba(255,255,255,.8);
}
#login-dp .help-block{
    font-size:10px !important
}
#login-dp .bottom{
    background-color:rgba(255,255,255,.8);
    border-top:1px solid #ddd;
    clear:both;
    padding:14px;
}
#login-dp .social-buttons{
    margin:12px 0    
}
#login-dp .social-buttons a{
    width: 49%;
}
#login-dp .form-group {
    margin-bottom: 10px;
}
.btn-fb{
    color: #fff;
    background-color:#3b5998;
}
.btn-fb:hover{
    color: #fff;
    background-color:#496ebc 
}
.btn-go{
    color: #fff;
    background-color:#DD4B39;
}
.btn-go:hover{
    color: #fff;
    background-color:#EB515D; 
}
.btn-tw{
    color: #fff;
    background-color:#55acee;
}
.btn-tw:hover{
    color: #fff;
    background-color:#59b5fa;
}
@media(max-width:768px){
    #login-dp{
        background-color: inherit;
        color: #fff;
    }
    #login-dp .bottom{
        background-color: inherit;
        border-top:0 none;
    }
}